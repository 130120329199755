import BaseOsirisService from '../BaseOsirisService';

class OptInService extends BaseOsirisService {
    async create(data) {
        try {
            return await this.api.post('/cerc/opt-in', data);
        } catch ({ response }) {
            return response;
        }
    }

    async index() {
        try {
            return await this.api.get('/cerc/opt-in');
        } catch ({ response }) {
            return response;
        }
    }

    async show(id) {
        try {
            return await this.api.get(`cerc/opt-in/${id}`);
        } catch ({ response }) {
            return response;
        }
    }

    async update(id, { start_date, end_date }) {
        try {
            return await this.api.put(`cerc/opt-in/${id}`, {
                start_date,
                end_date,
            });
        } catch ({ response }) {
            return response;
        }
    }

    async delete(id) {
        try {
            return await this.api.put(`cerc/opt-out/${id}`);
        } catch ({ response }) {
            return response;
        }
    }

    async getSchedules(id) {
        try {
            return await this.api.get(`cerc/schedule/opt-in/${id}`);
        } catch ({ response }) {
            return response;
        }
    }
}

export default new OptInService();
