export default class TagMapper {
    static map(data) {
        return data;
    }

    static toView(data) {
        return {
            ...data,
            payment_scheme: data.payment_scheme || '',
        };
    }
}
