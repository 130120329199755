<template>
  <div>
    <Page
      :is-edit="false"
      :merchant="merchant"
      @click-new="createOptIn"
    />
  </div>
</template>

<script>
import '@validations';

import Ripple from 'vue-ripple-directive';

import OptInService from '@/services/osiris/OptInService';
import CercOptInService from '@/services/osiris/Cerc/OptInService';

import TagMapper from '../mappers/Tag';
import CercMapper from '../mappers/Cerc';

import Page from '../components/Page.vue';

const mappers = {
    TAG: TagMapper,
    CERC: CercMapper,
};

const services = {
    TAG: OptInService,
    CERC: CercOptInService,
};

export default {
    components: {
        Page,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            merchant: this.getStoreMerchant(),
        };
    },

    methods: {
        async createOptIn(input) {
            this.isLoading(true);

            const { merchant } = this;

            const data = mappers[input.register].map({
                ...input,
                merchant_id: merchant.id,
            });

            const { status } = await services[input.register].create(data);

            const responseStatus = {
                200: 'Opt-In criado com sucesso',
                202: 'Está fora do horário de funcionamento, porém a criação foi agendada',
            };

            this.isLoading(false);

            if (status === 200 || status === 202 || status === 201) {
                this.modalSuccess(responseStatus[status]);
            } else {
                this.modalError('Não foi possível criar esse opt-in');
            }

            this.isLoading(false);
        },
    },
};
</script>
