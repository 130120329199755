<template>
  <b-form
    class="needs-validation mt-2"
    @submit.prevent
  >
    <b-row>
      <b-col md="3">
        <b-form-group
          label="Registradora"
          label-for="tax_id_beneficiary"
        >
          <validation-provider
            #default="{ errors }"
            name="Registradora"
            rules="required"
          >

            <b-form-select
              v-model="req.register"
              :disabled="isEdit || disabledAll"
              :options="registerOptions"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col md="3">
        <b-form-group
          label="CNPJ do Beneficiário"
          label-for="tax_id_beneficiary"
        >
          <validation-provider
            #default="{ errors }"
            name="CNPJ do Beneficiário"
            rules="cnpj"
          >

            <b-form-input
              id="tax_id_beneficiary"
              v-model="req.beneficiary"
              v-mask="
                '##.###.###/####-##'
              "
              placeholder="CNPJ do Beneficiaro"
              :disabled="isEdit || disabledAll"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="CNPJ do Adquirente"
          label-for="tax_id_buyer"
        >
          <validation-provider
            #default="{ errors }"
            name="CNPJ do Adquirente"
            rules="cnpj"
          >

            <b-form-input
              id="tax_id_buyer"
              v-model="req.acquirer"
              v-mask="
                '##.###.###/####-##'
              "
              placeholder="CNPJ do Adquirente"
              :disabled="isEdit || disabledAll"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col md="3">
        <b-form-group
          label="Assinatura do Contrato*"
          label-for="tax_id_adqui"
        >
          <validation-provider
            #default="{ errors }"
            name="Assinatura do Contrato"
            rules="required|before-date"
          >
            <b-form-input
              id="merchant_name"
              v-model="req.signature_date"
              placeholder="Assinatura do Contrato*"
              type="date"
              :disabled="isEdit || disabledAll"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider></b-form-group>
      </b-col>

      <b-col md="3">
        <b-form-group
          label="Data de Inicio*"
          label-for="start_date"
        >
          <validation-provider
            #default="{ errors }"
            name="Data de Inicio"
            rules="required|after-date"
          >
            <b-form-input
              id="start_date"
              v-model="req.start_date"
              placeholder="Data de Inicio"
              :disabled="disabledAll"
              type="date"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col md="3">
        <b-form-group
          label="Data Final*"
          label-for="final_date"
        >
          <validation-provider
            #default="{ errors }"
            name="Data Final"
            :rules="`required|end-date:${req.start_date}`"
          >
            <b-form-input
              id="final_date"
              v-model="req.end_date"
              placeholder="Data Final*"
              :disabled="disabledAll"
              type="date"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <label for="scheme">Esquema de Pagamento </label>
        <validation-provider
          #default="{ errors }"
          name="Esquema de Pagamento"
        >
          <b-form-select
            id="scheme"
            v-model="req.payment_scheme"
            :disabled="isEdit || disabledAll"
            :state="errors.length > 0 ? false : null"
            :options="schemesOptions"
          >
            <template v-slot:first>
              <b-form-select-option
                :value="''"
              >
                Todos
              </b-form-select-option>
            </template>
          </b-form-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>

    </b-row>

  </b-form>
</template>

<script>
import {
    BCol,
    BForm,
    BFormInput,
    BRow,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
} from 'bootstrap-vue';
import { ValidationProvider } from 'vee-validate';

import schemes from '@/utils/paymentSchemes';

import RegisterValidator from '@/register/validate-register';
import RegisterGetters from '@/register/get-registers';

export default {
    components: {
        ValidationProvider,
        BFormInput,
        BCol,
        BRow,
        BForm,
        BFormGroup,
        BFormSelect,
        BFormSelectOption,
    },

    props: {
        isEdit: {
            type: Boolean,
            default: () => false,
        },

        req: {
            type: Object,
            required: true,
        },

        disabledAll: {
            type: Boolean,
            default: () => false,
        },
    },

    data() {
        return {
            schemesOptions: schemes.map(scheme => ({
                text: scheme, value: scheme,
            })),
        };
    },

    computed: {
        registerOptions() {
            if (this.isEdit) {
                return RegisterGetters.get();
            }

            return RegisterValidator.validateWithoutSelectedOptIn();
        },
    },

};
</script>
  <style lang="scss" >

  .vs__deselect {
      fill: rgba(255, 255, 255, 0.9) !important;
  }

  .ec-tag {
    padding: 0.51rem 1rem !important;
  }

  .b-form-tags-button {
    display: none !important;
  }
  </style>
