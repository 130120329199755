export default class CercMapper {
    static map(data) {
        return {
            merchant_id: data.merchant_id,
            signature_date: data.signature_date,
            start_date: data.start_date,
            end_date: data.end_date,
            payment_schemes: data.payment_scheme ? [data.payment_scheme] : [],
            acquirers: data.acquirer ? [data.acquirer] : [],
        };
    }

    static toView({
        opt_in_payment_schemes: optInPaymentSchemes,
        opt_in_acquirers: optInAcquirers,
        ...data
    }) {
        return {
            ...data,
            payment_scheme: optInPaymentSchemes.length ? optInPaymentSchemes[0].payment_scheme.code : '',
            acquirer: optInAcquirers.length ? optInAcquirers[0].cnpj : '',
        };
    }
}
