/* eslint-disable max-classes-per-file */
/* eslint-disable no-unused-vars */
import store from '../store';

class UniqueRegisterValidator {
    registers = []

    constructor(
        registers,
    ) {
        this.registers = registers;
    }

    validate(
        register,
    ) {
        return this.registers.includes(register);
    }
}

export default class RegisterValidator {
    static validate() {
        const registers = (store.getters['register/register']);
        const {
            tag: tagOptInId,
            cerc: cercOptInId,
        } = (store.getters['creditPlatform/merchant']).optin_id;

        const validator = new UniqueRegisterValidator(registers);

        const registersValidated = [];

        if (cercOptInId && validator.validate('CERC')) {
            registersValidated.push('CERC');
        }

        if (tagOptInId && validator.validate('TAG')) {
            registersValidated.push('TAG');
        }

        return registersValidated;
    }

    static validateWithoutSelectedOptIn() {
        const registers = (store.getters['register/register']);
        return registers;
    }
}
