<template>
  <CreditPlatformItemProductLayout
    :breadcrumb-items="[
      {
        text: 'Inicio',
        href: '/',
      }, {
        text: 'Detalhes Estabelecimento',
        href: `/plataforma-credito/estabelecimento/detalhes/${merchant.id}`,
      }, {
        text: isEdit ? 'Editar Opt-In' : 'Novo Opt-In',
      }]"

    :optin-selected-is-required="false"
  >

    <b-card>
      <validation-observer
        #default="{invalid}"
        ref="simpleRules"
      >
        <Form
          :is-edit="isEdit"
          :req="req"
          :disabled-all="disabledAll"
        />

        <b-row
          v-if="!disabledAll"
          class="mt-1"
        >
          <b-col
            md="2"
            sm="6"
          >
            <b-button
              variant="primary"
              block
              :disabled="invalid"
              @click="confirm"
            >{{ isEdit ? 'Salvar' : 'Criar' }}</b-button>

          </b-col>

          <b-col
            md="2"
            sm="6"
          >
            <b-button
              v-if="isEdit"
              variant="danger"
              @click="cancelOptIn"
            >
              Cancelar OptIn
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>

  </CreditPlatformItemProductLayout>
</template>

<script>
import {
    BCard,
    BButton,
    BRow,
    BCol,
} from 'bootstrap-vue';
import { ValidationObserver } from 'vee-validate';
import '@validations';

import Ripple from 'vue-ripple-directive';

import Form from './Form.vue';

import CreditPlatformItemProductLayout from '@/views/layouts/CreditPlatformItemProductLayout.vue';

export default {
    components: {
        CreditPlatformItemProductLayout,
        ValidationObserver,
        Form,
        BCard,
        BButton,
        BRow,
        BCol,
    },
    directives: {
        Ripple,
    },

    props: {
        optin: {
            type: Object,
            default: () => ({}),
        },

        merchant: {
            type: Object,
            required: true,
        },

        isEdit: {
            type: Boolean,
            default: () => false,
        },

        disabledAll: {
            type: Boolean,
            default: () => false,
        },

    },
    data() {
        return {
            req: {
                register: 'TAG',
                acquirer: '',
                merchant_id: null,
                signature_date: '',
                start_date: '',
                end_date: '',
                payment_scheme: '',
                beneficiary: '',
            },

        };
    },

    mounted() {
        if (!this.objectIsEmpty(this.optin)) {
            this.req = this.optin;
        }
    },

    methods: {
        async confirm() {
            this.confirmAnAction(`Deseja realmente ${this.isEdit ? 'editar' : 'criar'} esse opt-in?`).then(() => (this.isEdit ? this.updateOptIn() : this.createOptIn()));
        },

        createOptIn() {
            this.isLoading(true);
            const { req, merchant } = this;
            const { acquirer, beneficiary } = req;
            const { asset_holder: assetHolder } = merchant;

            req.asset_holder = assetHolder ? assetHolder.replace(/[^a-zA-Z0-9]/g, '') : null;
            req.acquirer = acquirer ? req.acquirer.replace(/[^a-zA-Z0-9]/g, '') : null;
            req.beneficiary = beneficiary ? req.beneficiary.replace(/[^a-zA-Z0-9]/g, '') : null;
            const removeUselessData = dataWillBeRemoved => {
                Object.keys(dataWillBeRemoved).forEach(key => {
                    if (!dataWillBeRemoved[key]) {
                        // eslint-disable-next-line no-param-reassign
                        delete dataWillBeRemoved[key];
                    }
                });
            };

            removeUselessData(req);

            this.$emit('click-new', req);
        },

        async updateOptIn() {
            this.isLoading(true);
            const { start_date, end_date } = this.req;
            this.$emit('click-update', {
                start_date, end_date, id: this.req.id, register: this.req.register,
            });
        },

        async cancelOptIn() {
            const isAuthorizedToCancel = await this.confirmAnAction('Realmente deseja cancelar esse OptIn?');
            if (!isAuthorizedToCancel) return;
            this.$emit('click-cancel', {
                id: this.req.id, register: this.req.register,
            });
        },
    },
};
</script>
    <style lang="scss" >

    .vs__deselect {
        fill: rgba(255, 255, 255, 0.9) !important;
    }

    .ec-tag {
      padding: 0.51rem 1rem !important;
    }

    .b-form-tags-button {
      display: none !important;
    }
    </style>
