import BaseOsirisService from './BaseOsirisService';

class OptInService extends BaseOsirisService {
    async create({
        merchant_id,
        asset_holder,
        signature_date,
        start_date,
        end_date,
        payment_scheme,
        acquirer,
        beneficiary,
    }) {
        try {
            return await this.api.post('/tag/opt-in', {
                merchant_id,
                asset_holder,
                signature_date,
                start_date,
                end_date,
                payment_scheme,
                acquirer,
                beneficiary,
            });
        } catch ({ response }) {
            return response;
        }
    }

    async index() {
        try {
            return await this.api.get('/tag/opt-in');
        } catch ({ response }) {
            return response;
        }
    }

    async show(id) {
        try {
            return await this.api.get(`tag/opt-in/${id}`);
        } catch ({ response }) {
            return response;
        }
    }

    async update(id, { start_date, end_date }) {
        try {
            return await this.api.put(`tag/opt-in/${id}`, {
                start_date,
                end_date,
            });
        } catch ({ response }) {
            return response;
        }
    }

    async delete(id) {
        try {
            return await this.api.put(`tag/opt-out/${id}`);
        } catch ({ response }) {
            return response;
        }
    }

    async getSchedules(id) {
        try {
            return await this.api.get(`tag/schedule/opt-in/${id}`);
        } catch ({ response }) {
            return response;
        }
    }
}

export default new OptInService();
